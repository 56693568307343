<template>
    <footer class="text-black p-4 mt-4 border-t-2 border-neutral-300">
        <div class="container mx-auto text-center">
            <p class="font-Karantina font-extrabold text-2xl uppercase">&copy; 2024 - Sahabat Aji Rum.
            </p>
            <div class="flex justify-center">
                <a href="#" class="text-gray-400 hover:text-white mx-2"><svg xmlns="http://www.w3.org/2000/svg"
                        class="w-4 sm:w-6 h-4 sm:h-6" viewBox="0 0 512 512">
                        <path
                            d="M336 96c21.2 0 41.3 8.4 56.5 23.5S416 154.8 416 176v160c0 21.2-8.4 41.3-23.5 56.5S357.2 416 336 416H176c-21.2 0-41.3-8.4-56.5-23.5S96 357.2 96 336V176c0-21.2 8.4-41.3 23.5-56.5S154.8 96 176 96h160m0-32H176c-61.6 0-112 50.4-112 112v160c0 61.6 50.4 112 112 112h160c61.6 0 112-50.4 112-112V176c0-61.6-50.4-112-112-112z" />
                        <path
                            d="M360 176c-13.3 0-24-10.7-24-24s10.7-24 24-24c13.2 0 24 10.7 24 24s-10.8 24-24 24zM256 192c35.3 0 64 28.7 64 64s-28.7 64-64 64-64-28.7-64-64 28.7-64 64-64m0-32c-53 0-96 43-96 96s43 96 96 96 96-43 96-96-43-96-96-96z" />

                    </svg></a>
                <a href="#" class="text-gray-400 hover:text-white mx-2"><svg xmlns="http://www.w3.org/2000/svg"
                        class="w-4 sm:w-6 h-4 sm:h-6" viewBox="0 0 24 24" version="1.1">
                        <path
                            d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
                    </svg></a>
            </div>
        </div>
    </footer>
</template>